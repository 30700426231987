<template>
    <div class="section" key="appartement">
        <div class="section-title">
            <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
            <h3><span>Schritt 7</span> Letzte gemeinsame Wohnung</h3>
            <p>Für die Bestimmung des zuständigen Gerichts ist es erforderlich, dass Sie hier die Daten der letzten gemeinsamen Wohnung angeben</p>
        </div>
        <div class="form-container form-data">
            <div class="input-row">
            <div class="wrap-input">
                <input type="text" name="wohnungstrasse" v-model="user.strasse3" require><span class="label-input" v-bind:class="{ filled : user.strasse3.length &gt;= 1 }">Straße / Nr. *</span>
            </div>
            </div>
            <div class="input-row">
            <div class="wrap-input">
                <input type="text" name="wohnungort" v-model="user.ort3" require><span class="label-input" v-bind:class="{ filled : user.ort3.length &gt;= 1 }">Postleitzahl / Ort *</span>
            </div>
            </div>
            <div class="input-row">
            <div class="wrap-input">
                <select name="werbbleibt" v-model="user.wohnung_bleiben" @change="incrementChecked()" require>
                <option value="null" disabled>Bitte wählen</option>
                <option value="Ehemann/Ehepartner1">Ehemann / Ehepartner 1</option>
                <option value="Ehefrau/Ehepartner2">Ehefrau / Ehepartner 2</option>
                <option value="keiner">Keiner von beiden</option>
                </select><span class="label-input" v-bind:class="{ filled : user.wohnung_bleiben.length &gt;= 1 }">Wer bleibt in der Wohnung?</span>
            </div>
            </div>
            <div class="notification">Geben Sie an wer nach der Scheidung in der gemeinsamen Wohnung bleiben wird</div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'InfoApartment',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>